<template>
  <div>
    <base-template
        v-if="isReady"
        ref="baseTemplate"
        :title="$tc('employee',2)"
        :items="users2"
        :items-context="usersContext"
        :available-filters="store.getters['user2/getEmployeeAvailableFilters']"
        :payload.sync="payload"
        :module="module"
        :is-list-view-action-column-enabled="true"

        @addItem="employeeModalShow=true"
    >
      <!--      Cell status-->
      <template #listView_cell__isCurrentlyEmployed="{item}">
        <b-badge
            pill
            :variant="'light-'+(item._isCurrentlyEmployed?'success':'secondary')"
            class="text-capitalize"
        >
          {{ item._isCurrentlyEmployed?$t('UnderContract'):$t('WithoutContract') }}
        </b-badge>
      </template>

      <template #listView_cell_actions="{item}">
        <!--          Edit -->
        <button-edit
            @click.native.stop="$router.push({ name: 'Employee edit', params: { employeeId: item.id }})"
            v-b-tooltip.hover.left="$t('Edit')"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />
      </template>

    </base-template>


    <!-- modal new employee-->
    <new-employee-modal
        :newEmployee.sync="currentEmployee"
        title="NewEmployee"
        :isOpen.sync="employeeModalShow"
        @submitValidated="submitValidatedEmployeeLocal"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { useEmployees }         from '../../views/humanResources/employees/useEmployees'

import store  from '../../store'
import useAPI from '../../utils/useAPI'

import BaseTemplate     from '../base3/Base.vue'
import ButtonEdit       from '../button/Edit.vue'
import NewEmployeeModal from '../prompt/NewEmployee.vue'

export default {
  components: {
    ButtonEdit,
    BaseTemplate,
    NewEmployeeModal
  },
  props: {},
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const module = ref('employees')
    const payload = ref({})
    const isReady = ref(false)
    const isAutocompleteLoaded = ref(false)
    const currentEmployee = ref(null)
    const employeeModalShow = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { users2, usersContext } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(payload, val => {
      console.log(val)
      isReady.value = true

      fetchUsers2(val)
          .then(() => {
            if (!isAutocompleteLoaded.value) {
              loadInit()
            }
          })
    }, { deep: true })

    watch(employeeModalShow, val => {
      if (val == false) {
        resetEmployee()
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { fetchUsers2 } = useAPI()
    const { submitValidatedEmployee } = useEmployees()

    const loadInit = () => {

    }

    const resetEmployee = () => {
      currentEmployee.value = JSON.parse(JSON.stringify({}))
    }

    const submitValidatedEmployeeLocal = () => {
      // console.log(currentSupplierInvoice.value)
      submitValidatedEmployee(currentEmployee.value)
          .then(response => {
            resetEmployee()
            employeeModalShow.value = false
          })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    resetEmployee()

    store.dispatch('moduleView/getModuleViews')
        .then((response) => {
          let currentView = store.getters['moduleView/getCurrentModuleView'](module.value)
console.log(module.value)
console.log(currentView)
          payload.value = {
            filters: currentView.filters,
            kanbanSortBy: currentView.kanbanSortBy,
            kanbanColumnAmount: currentView.kanbanColumnAmount,
            listGlobalAmount: currentView.listGlobalAmount,
            page: 1,
            sorts: currentView.sorts,
          }

        })

    return {
      // Components

      // Data
      module,
      payload,
      employeeModalShow,
      currentEmployee,
      isReady,

      // Computed
      users2,
      usersContext,

      // Methods
      submitValidatedEmployeeLocal,
    }
  },
  data () {
    return {}
  },
  computed: {
    store () {
      return store
    }
  },
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>